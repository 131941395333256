/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Importing website framework css for use here, Global rules should be okay for this, set more specific stuff on a page by page basis */

/* Column layouts */

div.twoCols, 
div.threeCols, 
div.fourCols, 
div.fiveCols, 
div.sixCols {
	display: flex;
    flex-flow: row wrap;
	clear: both;
    box-sizing: border-box;
	width: 100%;
}

div.twoCols {
    .col {
        &:nth-child(2n-1) {
            position: relative;
            float: left;
            clear: left;
            width: 49%;
            margin-right: 1%;
        }
        &:nth-child(2n-0) {
            position: relative;
            float: right;
            width: 49%;
            margin-left: 1%;
        }
    }
}

div.twoCols.twoThirds {
    margin-bottom: 1em;
    .col {
        &:nth-child(2n-1) {
            position: relative;
            float: left;
            clear: left;
            width: 59%;
            margin-right: 1%;
        }
        &:nth-child(2n-0) {
            position: relative;
            float: right;
            width: 39%;
            margin-left: 1%;
        }

        &.pictureCol {
            margin-top: -49px;
    
            @media screen and (max-width: 1300px) {
                margin-top: 0;
            }
        }

        &.noHeading {
            margin-top: 55px;

            @media screen and (max-width: 1300px) {
                margin-top: 0;
            }
        }
    }
   
}
div.twoCols.twoThirdsReverse {
    .col {
        &:nth-child(2n-1) {
            position: relative;
            float: left;
            clear: left;
            width: 39% !important;
            margin-right: 1% !important;
        }
        &:nth-child(2n-0) {
            position: relative;
            float: right;
            width: 59% !important;
            margin-left: 1% !important;
        }
    }
}

div.twoCols.layout1 {
    .col {
        &:nth-child(2n-1) {
            width: 31%;
            margin-right: 3%;
        }

        &:nth-child(2n-0) { 
            width: 63%;
            margin-left: 3%;
        }
    }
}

div.threeCols {
    .col {
        &.col:nth-child(3n-2) {
            position: relative;
            float: left;
            width: 32%;
            margin-right: 1%;
        }
        &.col:nth-child(3n-1) {
            position: relative;
            float: left;
            width: 32%;
            margin-right: 1%;
            margin-left: 1%;
        }
        &.col:nth-child(3n-0) {
            position: relative;
            float: right;
            width: 32%;
            margin-left: 1%;
        }
    }
}

div.fourCols {
    .col {
        &:nth-child(4n-3) {
            position: relative;
            float: left;
            width: 24%;
            margin-right: 0.6666%;
        }
        &:nth-child(4n-2) {
            position: relative;
            float: left;
            width: 24%;
            margin-right: 0.6666%;
            margin-left: 0.6666%;
        }
        &:nth-child(4n-1) {
            position: relative;
            float: left;
            width: 24%;
            margin-right: 0.6666%;
            margin-left: 0.6666%;
        }
        &:nth-child(4n-0) {
            position: relative;
            float: left;
            width: 24%;
            margin-left: 0.6666%; 
        }
    }
}

div.fiveCols {
    .col {
        &.col:nth-child(5n-4) {
            position: relative;
            float: left;
            width: 19%;
            margin-right: 0.625%;
        }
        &.col:nth-child(5n-3) {
            position: relative;
            float: left;
            width: 19%;
            margin-right: 0.625%;
            margin-left: 0.625%;   
        }
        &.col:nth-child(5n-2) {
            position: relative;
            float: left;
            width: 19%;
            margin-right: 0.625%;
            margin-left: 0.625%;
        }
        &.col:nth-child(5n-1) {
            position: relative;
            float: left;
            width: 19%;
            margin-right: 0.625%;
            margin-left: 0.625%;  
        }
        &.col:nth-child(5n-0) {
            position: relative;
            float: right;
            width: 19%;
            margin-left: 0.625%;
        }
    }
}

div.sixCols {
    .col {
        &.col:nth-child(6n-5) {
            position: relative;
            float: left;
            width: 16%;
            margin-right: 0.4%;
        }
        &.col:nth-child(6n-4) {
            position: relative;
            float: left;
            width: 16%;
            margin-right: 0.4%;
            margin-left: 0.4%;
        }
        &.col:nth-child(6n-3) {
            position: relative;
            float: left;
            width: 16%;
            margin-right: 0.4%;
            margin-left: 0.4%;
        }
        &.col:nth-child(6n-2) {
            position: relative;
            float: left;
            width: 16%;
            margin-right: 0.4%;
            margin-left: 0.4%;
        }
        &.col:nth-child(6n-1) {
            position: relative;
            float: left;
            width: 16%;
            margin-right: 0.4%;
            margin-left: 0.4%;
        }
        &.col:nth-child(6n-0) {
            position: relative;
            float: left;
            width: 16%;
            margin-left: 0.4%;
        }
    }
}

/* Responsive stlying for all column layouts */

div.twoCols, 
div.threeCols, 
div.fourCols, 
div.fiveCols, 
div.sixCols {
    @media screen and (max-width: 767px) {
        float: left !important;
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .col {
        &:nth-child(2n-1) {
            @media screen and (max-width: 767px) {
                width: 49% !important;
                clear: left !important;
                margin-right: 1% !important;
                margin-left: 0 !important;
            }
        }
        &:nth-child(2n-0) {
            @media screen and (max-width: 767px) {
                width: 49% !important;
                clear: left !important;
                margin-left: 1% !important;
                margin-right: 0 !important;
            }
        }
        &:nth-child(2n-1) {
            @media screen and (max-width: 600px) {
                width: 100% !important;
                clear: left !important;
                margin: 0 !important;
            }
        }
        &:nth-child(2n-0) {
            @media screen and (max-width: 600px) {
                width: 100% !important;
                clear: left !important;
                margin: 0 !important;
            }
        }
    }
}

/* Content Items these usually appear in widgets on the website */

.contentItem {
    .header {

    }
    .thumbnail {
        line-height: 0;
        padding-right: 20px;
        img {

        }
        &.original {
            img {
                max-width: 100%;
                max-height: none;
                width: auto;
                height: auto;
            }
        }
        &:not(.original) {
            img {
                width: 100%;
                max-width: 100%;
                height: auto;
                max-height: none;
            }
        }
        &.huge {
            width: 320px;
        }
        &.large {
            width: 240px;
        }
        &.medium {
            width: 160px;
        }
        &.small {
            width: 100px;
        }
        &.tiny {
            width: 80px;
        }
    }
    .text {
        .title {
            .publishInfo {
                margin-bottom: 10px;
                .date,
                .author {
                    display: inline-block;
                }
            }
        }
        .summary {
            margin: 0 0 1.5em;
            .readMore {
                display: block;
                margin-top: 0.75em;
            }
        }
    }
    &:first-child,
    &.last-child {
        margin-top: 0 !important;
    }
}

/* .imageLeft and .imageRight for content items */

.imageLeft,
.imageRight {
    .contentItem {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
        margin-bottom: 1.5em;
        .thumbnail {
            max-width: 50%;
            flex-shrink: 0; /* Don't get smaller. */
        }
        .text {
            width: 100%;
            flex-grow: 0; /* Don't grow and push the image around. */
            .title {
                margin-top: 0;
            }
        }
    }
}

.imageLeft {
    .contentItem {
        .thumbnail {
            order: 1;
        }
        .text {
            order: 2;
        }
    }
}

.imageRight {
    .contentItem {
        .thumbnail {
            order: 2;
            padding-left: 20px;
            padding-right: 0;
        }
        .text {
            order: 1;
        }
    }
}

/* Cards stlying for contentItems */

.cards {
    display: inline-block;
    width: 100%; /* For IE11, which we still support. */
    .paging {
    }
    .content {
        .contentItem {
            position: relative;
            box-sizing: border-box;
            float: left;
            clear: none;
            width: 32%;
            margin: 0 0 1.5em;
            overflow: hidden;
            background: #d8d4d0;
            &:nth-child(3n-1) {
                /* The middle one */
                margin: 0 2% 1.5em; 
            }
            &:nth-child(3n+1) {
                clear: left;
            }
            .thumbnail {
                position: relative;
                float: left;
                max-width: 100%;
                width: 100%;
                /*height: 200px;*/
                padding: 0 !important;
                box-sizing: border-box;
                text-align: center;
                overflow: hidden;
                a {
                    img {
                        display: block;
                        min-width: 100%;
                        min-height: 100%;
                        max-width: 100% !important;
                    }
                }
            }
            .text {
                display: flex;
                flex-flow: wrap;
                box-sizing: border-box;
                position: relative;
                float: left;
                width: 100%;
                padding: 20px;
                * {
                    width: 100%;
                }
                .title {
                    margin-top: 0;
                    a {
                        display: block;
                        margin: 0;
                    }
                }
                .publishInfo {
                    display: inline-block;
                    width: 100%; /* Otherwise it does odd things in .masonry views */
                    box-sizing: border-box;
                    /*order: -1;*/ /* Make sure it's first in the flex wrap .text box */ 
                    * {

                    }
                }
                .summary {
                    .readMore {
                        display: block;
                        margin-top: 10px;
                    }
                }
                &:last-child {
                    margin-bottom:  0 !important;
                }
            }
        }
    }
    .noContent {
        margin: 0 0 1.5em;
    }
    &.panorama .content .contentItem {
        .thumbnail {
            position: relative;
            height: 150px;
            max-height: 150px;
            img,
            a img {
                position: absolute;
                top: -99999em;
                right: -99999em;
                left: -99999em;
                bottom: -99999em;
                margin: auto !important;
                min-width: 100%;
                min-height: 100%;
                max-width: none;
                max-height: none;
                width: auto;
                height: auto;
                margin: auto;
            }
        }
    }
    &.panorama.fullWidth .content .contentItem {
        width: 100% !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    &.padded .content .contentItem {
        .thumbnail {
            width: calc(100% - 40px);
            margin: 20px 20px 0 !important;
        }
        .text {
            .date {
            }
        }
    }
    &.dateOverlay .content .contentItem {
        .text {
            .publishInfo {
                /* Note: Won't work well when there's no thumbnail, it'll disappear outside the .card box boundary */
                position: absolute;
                left: 0;
                top: -60px;
                /*background-color: rgba(255,255,255,0.75);*/
                padding: 15px 20px;
                font-size: 0.925rem;
                font-weight: bold;
                line-height: 125%;
            }
        }
    }
    &.padded.dateOverlay .content .contentItem {
        .text {
            .publishInfo {
                padding-left: 45px;
                * {
                    color: #fff;
                    text-shadow: 1px 1px 3px rgba(0,0,0,1); 
                }
            }
        }
    }
}

/* Responsive styling for cards */

@media screen and (max-width: 767px) {
    .cards .content .contentItem {
        width: 47.5%;
        &:nth-child(3n+1) {
            clear: none;
        }
        &:nth-child(3n-1) {
            margin: 0 0 40px;
        }
        &:nth-child(2n-1) {
            margin: 0 5% 40px 0;
        }
    }
}

@media screen and (max-width: 480px) {
    .cards .content .contentItem {
        width: 100%;
        &:nth-child(3n-1),
        &:nth-child(2n-1) {
            margin: 0 0 40px 0 !important;
        }
    }
}

/* ####################################
   General Form HTML Tags
   ################################## */

   .formPanel, 
   .dataContainer {
       position: relative;
       float: none;
       clear: both; /* RW: Is this a good idea? */
       margin: 0;
       padding: 0;
       width: 100%;
       margin-bottom: 1.5em;
   }
   
   fieldset, 
   .fieldset {
       position: relative;
       display: inline-block;
       box-sizing: border-box;
       width: 100%;
       max-width: 100%;
       min-width: 100%; /* Important for some browsers (including Firefox and Chrome) to stop growing too wide with visual editors and other content with stubborn width settings. */
       padding: 20px;
       margin: 0 0 1.5em;
       border: #ebebeb 2px solid;
       border-radius: 0;
   }
   
       fieldset legend, 
       .fieldset .legend {
           position: relative;
           float: left;
           box-sizing: content-box;
           width: 100%;
           padding: 10px 22px;
           margin: -22px -22px 22px;
           color: #000000;
           font-weight: bold;
           font-size: 125%; /* Default is equivalent to h3. */
           background: #ebebeb;
       }
   
       fieldset .summary, fieldset p, fieldset h1, fieldset h2, fieldset h3, fieldset h4, fieldset h5, fieldset h6,
       .fieldset .summary, .fieldset p, .fieldset h1, .fieldset h2, .fieldset h3, .fieldset h4, .fieldset h5, .fieldset h6 {
           clear: left; /* Fix floating next to div.legend */
       }
   
   /* ####################################
      Form & Data Fields Lists
      ################################## */
   
   .formFields, 
   .dataFields {
       display: inline-block !important; /* Nothing should mess with this. Make sure floated elements inside the form don't cause headaches for things around the form. */
       width: 100% !important; /* Nothing should mess with this. */
       padding: 25px !important; /* Nothing should mess with this. */
       margin: 0 !important;  /* Nothing should mess with this. */
       list-style: none !important;  /* Nothing should mess with this. */
   }
   
       .formFields > li, 
       .dataFields > li {
           list-style: none;
       }
   
       /* Don't inherit list-style for child lists of .formFields by default, because they're probably regular content. */
   
       .formFields ol, 
       .dataFields ol {
           list-style: decimal;
           margin-bottom: 1.5em;
       }
   
       .formFields ul, 
       .dataFields ul {
           list-style: disc;
           margin-bottom: 1.5em;
       }
   
   /* ####################################
      Form & Data Field Wrappers (usually inside lists)
      ################################## */
   
   .fieldContainer, 
   .formFields > li, 
   .dataFields > li {
       position: relative;
       float: left;
       clear: left;
       box-sizing: border-box;
       width: 100%;
       padding: 0;
       margin: 0.5em 0;
   }
   
       /* The following wrappers should be inside a .fieldContainer wrapper if the .fieldContainer class is used. However the CSS below does not specify .fieldContainer so that the following classes can also be used when .fieldContainer is not used (e.g. in simple forms). */
   
       .fieldTitle, 
       .formFields label, 
       .dataFields label {
           position: relative;
           float: left;
           box-sizing: border-box;
           width: 20%;
           padding: 10px 10px 10px 0;
           font-weight: bold;

           @media screen and (max-width: 900px) {
            width: 100%;
        }
       }
   
       .fieldContent, 
       .fieldControls, 
       .fieldData, 
       .fieldText {
           /* Legacy support: It's possible due to old code that .fieldControls/.fieldData/.fieldText exists without a .fieldContent wrapper. */
           display: block;
           position: relative;
           float: left;
           box-sizing: border-box;
           width: 100%;
           max-width: 80%;

           @media screen and (max-width: 900px) {
               max-width: 100%;
           }
       }
   
               .fieldContent .fieldControls, 
               .fieldContent .fieldData, 
               .fieldContent .fieldText {
                   /* When these fields ARE inside the .fieldContent wrapper. Might need to override settings from .fieldControls/.fieldData above. */
                   display: inline-block;
                   float: none;
                   box-sizing: border-box;
                   padding: 0;
                   width: 100%; /* Make sure the contents are contained and don't spill outside. Adjust this and .helperControl if you want help notes floating to the right of a field. */
                   max-width: 100%;
                   border-radius: 0;
                   background: none;
               }
   
               .fieldContent .fieldData, 
               .fieldContent .fieldText {
                   padding-top: 10px;
               }
   
               .fieldContent .fieldData > :last-child, 
               .fieldContent .fieldText > :last-child {
                   margin-bottom: 0 !important;
               }
   
               .fieldData ~ *, .fieldText ~ * {
                   /* Default separation for elements below .fieldText. You might want to override this in some cases. */
                   margin-top: 1em !important;
               }
   
                   .fieldContent .fieldData ul, 
                   .fieldContent .fieldText ul, 
                   .fieldContent .fieldData ol, 
                   .fieldContent .fieldText ol {
                       margin: 0.75em 0;
                   }
                                           
                       .fieldContent .fieldData ul li, 
                       .fieldContent .fieldText ul li {
                           list-style: disc;
                           margin: 0 0 5px !important;
                       }
   
               .fieldContent .fieldContentTop {
                   display: inline-block;
                   margin: 0 0 4px;
                   width: 100%;
               }
   
               .fieldContent .fieldContentBottom {
                   display: inline-block;
                   clear: left;
                   margin: 4px 0 0;
                   width: 100%;
               }
   
               .fieldContent .helperControl {
                   margin-top: 10px;
                   padding-left: 24px;
               }
   
                   .fieldContent .helperControl:before {
                       content: "i";
                       position: relative;
                       display: inline-block;
                       vertical-align: middle;
                       box-sizing: border-box;
                       padding: 1px 3px;
                       margin: -3px 3px 0 -24px;
                       width: 16px;
                       font-family: Courier New, Courier, monospace;
                       font-weight: bold;
                       font-size: 13px;
                       line-height: 11px;
                       color: #0000d8;
                       border: #0000d8 2px solid;
                       border-radius: 100%;
                   }
   
                   .fieldControls > .helperControl {
                       vertical-align: top;
                   }
   
               .fieldContent > h1,
               .fieldContent > h2,
               .fieldContent > h3,
               .fieldContent > h4,
               .fieldContent > h5,
               .fieldContent > h6,
               .fieldContent > p,
               .fieldContent > ul, 
               .fieldContent > ol {
                   margin: 0.25em 0;
               }

               .fieldControls.fileUpload {
                   .file-input-container {
                       label {
                        //   text-align: center; 
                       } 
                   }
                   h2 {
                        //    text-align: right;
                       }
                    .list-md {
                    }
               }

.desktopMenu {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    div {
        display: flex;
        align-items: center;

        .userRoleCheck {
            display: flex;
            position: relative;
            align-items: center;
            .label {
               margin-right: 10px; 
            }
            .userRole {
                font-weight: bold;
                color: #006d6d;
                font-size: 1.2em;
            }
        }

       ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        li {
            display: flex;
            align-items: center;
            list-style-type: none;
            margin-right: 15px;
            color: #fff;
            font-weight: bold;
            text-decoration: none;
            cursor: pointer;
            }
        } 
    }

    @media screen and (max-width: 1300px) {
        display: none;
    }
    
}

ion-content {
    font-size: 16px;
}

.featureHeading {
    font-size: 76px;
    font-weight: bold;
    color: #006d6d;

    @media screen and (max-width: 1200px) {
        font-size: 48px;
    }

    @media screen and (max-width: 767px) {
        font-size: 36px;
    }
}

.mainContent {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
}

.mainPartialContent {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto; 
}


.mainContent.visible {
    opacity: 1;
    transition: opacity 1.5s;
}

.mainContent.hidden {
    opacity: 0;
}

.mainPartialContent.visible {
    opacity: 1;
    transition: opacity 1.5s;
}

.mainPartialContent.hidden {
    opacity: 0;
}
   
.topManagement {
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    width: 100%;

    h1 {
        margin: 0 40px 10px 0;
    }

    .buttons {
        display: flex;
        flex-wrap: wrap;
        margin-left: auto;
        align-items: center;

        ion-icon {
            color: #006d6d;
        }

        @media screen and (max-width: 500px) {
            margin: 0;
            width: 100%;
        }
    }
    .quotePaging {
        display: flex;
        margin-left: 5px;
        .next,
        .previous {
            color: #ffffff;
            background-color: #006d6d;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            text-indent: -2000000000000em;
            position: relative;
            background-size: 28px 28px;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
        }

        .previous{
            background-image: url("./assets/imgs/arrow-alt-circle-left-solid.png");
            margin-right: 10px;
        }

        .next {
            background-image: url("./assets/imgs/arrow-alt-circle-right-solid.png");
        }

        @media screen and (max-width: 500px) {
            margin: 5px 0;
            width: 100%;
        }
        
    }
}
.menu {
    @media screen and (max-width: 767px) {
        display: none;
    }
}

ion-menu-button {
    height: 58px;
    width: 58px;
    font-size: 54px;
    color: var(--cap-primary-color);

    &:hover,&:focus {
        color: #006d6d;
        --color-hover: #006d6d !important;
    }

    
}

ion-button::part(native) {
    text-transform: capitalize;
    border-radius: 0;
    background-color: var(--cap-primary-color);
    font-size: 16px;
}

ion-button.button-clear::part(native) {
    background-color: transparent;
    color: var(--cap-primary-color);
}

ion-button.rounded::part(native) {
    border-radius: 50px;
}

ion-button.menuButton::part(native) {
    font-size: 16px;
    font-weight: 400;
}

ion-button.logOutButton::part(native) {
    background: transparent;
    border: 2px solid #fff;
}

// Header with search and menu styling
.headerTools {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    div {
        // overflow: hidden;
    }

    .headerLeft {
        display: flex;
        img {
            max-width: 180px;
            max-height: 53px;
        }
    }
}

.topMenu {
    @media screen and (max-width:500px) {
        flex-direction: column;
    }
}

.flexCenter {
    display: flex;
    align-items: center;
}

.flexWrap {
    flex-wrap: wrap;
}

//Use this class on ion-content tags for pages which dont need to span full width on desktop
.partialWidth {
    display: flex;
    align-self: center;
    max-width:1200px;
    width: 100%;
}

input,
select {
    background-color: #fff;
    padding: 5px;
    max-width: 100%;
    width: 100%;
}

.legend {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 3.25em;
    align-items: center;
    color: #006d6d;
    margin-bottom: 15px;
    div {
        flex-grow: 1;
        align-self: flex-end;
        @media screen and (max-width: 700px) {
            flex-grow: unset;
            margin-right: 10px;
        }
        img {
            width: 15px;
            height: 15px;
            margin-right: 5px;
        }
    }
}

.primaryBox {
    background: white;
    padding: 10px;
    width: 100%;
}

.secondaryBox {
    background:#bfcecb;
    padding: 10px;
    width: 100%;
}

.transparentBg {
    background: none;
}

.fullWidth {
    width: 100%;
}

.bold {
    font-weight: 700;
}

.alert-radio-label.sc-ion-alert-md,
[aria-checked=true].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
    color: #000;
}

.bidList {
    .bid {
        &.firstBid {
            color: #fff;
            background: #006d6d !important;
            .company {
                .companyRating { 
                    button {
                        ion-icon {
                            color: #fff !important;
                        }
                    }
                }
            }
            .questions {
                .question {
                    img {
                        filter: grayscale(1) brightness(4.5);
                    }
                }
            }
            .status {
                &:after {
                    filter: grayscale(1) brightness(4.5);
                }
            }
            .managementButtons {
                ion-button::part(native) {
                    background-color: var(--cap-secondary-color);
                }
            }
        }
        &.promoted {
            color: #fff;
            background: #384286 !important;
            .company {
                .companyRating { 
                    button {
                        ion-icon {
                            color: #fff !important;
                        }
                    }
                }
            }
            .questions {
                .question {
                    img {
                        filter: grayscale(1) brightness(4.5);
                    }
                }
            }
            .status {
                &:after {
                    filter: grayscale(1) brightness(4.5);
                }
            }
        } 
    }
}

.accordionPanel {
    .quoteItem {
        &.firstBid {
            color: #fff;
            background: #006d6d !important;
            .seller {
                .rating { 
                    button {
                        ion-icon {
                            color: #fff !important;
                        }
                    }
                }
            }
        }
        &.promoted {
            color: #fff;
            background: #384286 !important;
            .seller {
                .rating { 
                    button {
                        ion-icon {
                            color: #fff !important;
                        }
                    }
                }
            }
        }
    }
}
    
.listItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 7px 10px;
    margin-bottom: 15px;
    box-sizing: border-box;
    background-color: var(--ion-background-color);

    &.new {
        border: #006d6d 2px solid;
    }

    &.promoted {
        color: #fff;
        background: #384286 !important;

        .listItemRow {
            .companyDetails {
                .sellerRating {
                    button {
                        ion-icon {
                            color: #fff !important;
                        }
                    }
                }
            }
            .managementPanel {
                div {
                    &.status {
                        &:after {
                            filter: grayscale(1) brightness(4.5);
                        }
                    }
                }
            }
            .question {
                img {
                    filter: grayscale(1) brightness(4.5);
                }
            }
        }
    }

    &.firstBid {
        color: #fff;
        background: #006D6D;

        .listItemRow {
            .companyDetails {
                .sellerRating {
                    button {
                        ion-icon {
                            color: #fff !important;
                        }
                    }
                }
            }
            .managementPanel {
                div {
                    &.status {
                        &:after {
                            filter: grayscale(1) brightness(4.5);
                        }
                    }
                }
            }
            .question {
                img {
                    filter: grayscale(1) brightness(4.5);
                }
            }
        }
    }

    .listItemRow {
        display: flex;
        width: 100%;
        .managementPanel {
            display: flex;
            flex-grow: 1;
            align-self: center;
            max-width: 40%;
            width: 100%;
            div {
                margin-right: 5px;
                &.status {
                    position: relative;
                    width: auto;
                    flex-grow: 0;
                    padding-left: 22px;

                    &:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        width: 20px;
                        height: 20px;
                    }

                    &.bidsubmitted {
                        &:after {
                            background-image: url(./assets/imgs/paper-plane-solid.png);
                            background-size: contain;
                        }
                    }
                    &.new {
                        &:after {
                            background-image: url(./assets/imgs/envelope-solid.png);
                            background-size: contain;
                        }
                    }
                    &.viewed {
                        &:after {
                            background-image: url(./assets/imgs/envelope-open-solid.png);
                            background-size: contain;
                        }
                    }
                    &.updaterequested {
                        &:after {
                            background-image: url(./assets/imgs/exclamation-solid.png);
                            background-size: contain;
                        }
                    }
                    &.accepted {
                        &:after {
                            background-image: url(./assets/imgs/check-solid.png);
                            background-size: contain;
                        }
                    }
                    &.rejected {
                        &:after {
                            background-image: url(./assets/imgs/times-solid.png);
                            background-size: contain;
                        }
                    }
                    &.cancelled {
                        &:after {
                            background-image: url(./assets/imgs/ban-solid.png);
                            background-size: contain;
                        }
                    }
                }
            }

            .question {
                width: 100%;
                max-width: 24px;
                margin-right: 5px;
                img {
                    max-width: 21px;
                }
            }
            .requestTimer {
                min-width: 70px;
            }
            .unreadQuestion {
                min-width: 15px;
                text-align: end;
            }
        }
        .requestData {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-self: center;
            div {
                max-width: calc(50% - 5px);
                width: 100%;
            }
        }
        .bidData {
            display: flex;
            width: 100%;
            div {
                flex-grow: 1;
                &.orderProgress {
                    max-width: 75px;
                    width: 100%;
                    flex-grow: 0;
                    align-self: center;
                    height: 21px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.inputBg {
    background: #fff;
}

.marginBottomLg {
    margin-bottom: 1.5em;
    @media screen and (max-width: 400px) {
        margin-bottom: 0.75em;
    }
}

.marginBottomMd {
    margin-bottom: 1em;
    @media screen and (max-width: 400px) {
        margin-bottom: 0.5em;
    }
}

.marginBottomSm {
    margin-bottom: 0.5em;
    @media screen and (max-width: 400px) {
        margin-bottom: 0.25em;
    }
}

.paddingSm {
    padding: 10px;

    @media screen and (max-width: 400px) {
        padding: 5px;
    }
}

.primaryTheme {
    color: #ffffff;
    background: #006d6d;
}

h1,h2,h3,h4,h5,h6 {
    &.primaryTheme {
        width: 100%;
    }
}
.filterPanel {
    display: flex;
    flex-wrap:wrap;
    margin: 10px 0;
    .filter {
        display: flex;
        flex-direction: column;
        margin: 10px;
        &.button {
            background: #006d6d;
            color: #fff;
            padding: 2px 7px;
            border-radius: 5px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.partialWidth {
    width: 59% !important;

    @media screen and (max-width: 1300px) {
        width: 100% !important;
    }
}

.companyName {
    cursor: pointer;
}

.orderSummary,
.quoteSummary {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 59%;

    .bidState {
        display: flex;
        .time,
        .price,
        .status {
            max-width: 200px;
            .label {
                display: block;
                font-weight: bold;
            }
            .data {
                font-size: 36px;
                font-weight: bold;
                line-height: 1em;
                @media screen and (max-width: 767px) { 
                    font-size: 28px;
                }
            }
        }
        .price {
            max-width: unset;
        }  
    }

    &:nth-child(even) {
        width: 40%;
        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }

    &:nth-child(odd) {
        width: 60%;
        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }

    .price {
        @media screen and (max-width: 600px) {
            order: 5;
        }
    }
    @media screen and (max-width: 1300px) {
        width: 100%;
    }  

    
}

.quoteData {
    margin-bottom: 0.5em;
    .label {
        font-weight: bold;
    }
    .data {

    }

    &.price {
        .label {
            display: block;
            font-size: 12px;
        }
        .data {
            font-size: 36px;
            font-weight: bold;
            line-height: 0.9em;
            @media screen and (max-width: 767px) { 
                font-size: 28px;
            }
        }
    }
}

table {
    &.vehicleTable,
    &.companyTable {
        width: 100%;
        text-align: left !important;
        border-spacing: 5px;
        border-collapse: separate;

        th {
            padding: 10px 0 10px 10px;
            width: 30%;
            font-weight: bold;
            @media screen and (max-width: 500px) {
                display: block;
                width: 100%;
                padding: 5px 0;
            }
        }
        td {
            padding: 10px;
            width: 70%;
            background: #fff;
            word-break: break-word;
            @media screen and (max-width: 500px) {
                display: block;
                width: 100%;
            }
        }
    }
}
.orderMessages {
    max-height: 140px;
    height: 140px;
    overflow-y: auto;
    .message {
        h4 {
            margin-top: 0;
        }
    }
    
}
.orderImages {
    display: flex;
    height: 175px;
    overflow-x: scroll;
    img {
        height: 100%;
        width: auto;
        cursor: pointer;
    }
}

.sellerSummary {
    width: 59%;
    margin-bottom: 1.5em;

    .orderSummary,
    .quoteSummary {
        width: 100% !important;
    }

    @media screen and (max-width: 1300px) {
       
            width: 100%;
        
    }
}

.quoteDetails {
    width: 100%;
    margin-bottom: 1.5em;

    table {
        tr.data {
            td {
                

                &.type {
                    max-width: 200px;   

                    @media screen and (max-width: 1000px) {
                        max-width: 100%;
                    }
                }

                &.quantity {
                    // max-width: 90px;
                }
                &.unitPrice,
                &.gstPrice {
                    max-width: 100px;
                    // width: 100px;

                    @media screen and (max-width: 1000px) {
                        max-width: 100%;
                    }
                }

                &.deleteRow {
                    width: 75px;
                }
            }
        }
    }
}

table.responsive {
    width: 100%;
    border-collapse: collapse; 
   
    /* Zebra striping */
    tr:nth-of-type(even) { 
        background: #eee; 
    }
    tr:nth-of-type(odd) { 
        background: #fff; 
    }

    tr.partialRow {
        background: none;
        td {
            border-right: none;
            @media screen and (max-width: 1000px) {
                padding: 0;
                display: none !important;
            }

            &.shippingPrice {
                display: table-cell !important;
                .shipping {
                    justify-content: flex-end; 
                }
            }
            &:before {
                display: none;
            }
        }
    }

    tr.headersRow {
        background: none !important;
        border-bottom: 2px solid #006d6d ;
        th {
            border-left: 2px solid #006d6d;
            border-right: 2px solid #006d6d;

            &:first-of-type {
                border-left: none;
            }

            &:last-of-type {
                border-right: none;
            }

            &.item {
                width: 65px
            }

            &.parts {
                width: 350px;
            }

            &.photos {
                width: 150px
            }

            &.quantity {
                width: 55px;
            }

            &.type {
                width: 140px;
            }

            &.notes {
                width: 270px;
            }

            &.unitPrice {
                width: 100px;
            }

            &.totalPrice {
                width: 150px;
            }
            &.buttons {
                width: 5%;
            }
        }
    }
    tr {
        &.data {
            padding: 10px;
        }
    }
    td, th { 
        padding: 10px 6px; 
        text-align: left; 
    }
    th { 
        background: none; 
        color: #000; 
        font-weight: bold; 
        text-align: left;
        border: none;
    }
    td {
        border-right: 2px solid lightgrey;

        &:last-of-type {
            border: none;
        }
    }
    

    td.itemNo,
    td.quantity {
        text-align: center;
    }

    td.gstPrice,
    td.unitPrice {
        text-align: end;
    }

    td.itemNo {
        .qrId {
            padding: 10px 0;
        }
    }
}

@media 
only screen and (max-width: 1000px)  {

	/* Force table to not be like tables anymore */
	table.responsive, 
    table.responsive thead, 
    table.responsive tbody, 
    table.responsive th, 
    table.responsive td, 
    table.responsive tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	table.responsive thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	table.responsive tr { border: none }
	
	table.responsive td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: none; 
		position: relative;
		padding-left: 30% !important; 
	}
	
	table.responsive td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}

    table.responsive {
        td.itemNo,
        td.quantity,
        td.unitPrice,
        td.gstPrice {
            text-align: left;
        }

        td.gstPrice {
            padding-bottom: 30px;
        }
    }

    @media screen and (max-width: 400px) {
        table.responsive td { 
            padding-left: 40% !important; 
        }
    }
	
	/*
	Label the data
	*/
	table.responsive td:nth-of-type(1):before { content: "Item No"; font-weight: bold;}
	table.responsive td:nth-of-type(2):before { content: "Parts"; font-weight: bold;}
	table.responsive td:nth-of-type(3):before { content: "Photo's"; font-weight: bold;}
	table.responsive td:nth-of-type(4):before { content: "Qty"; font-weight: bold;}
	table.responsive td:nth-of-type(5):before { content: "Type"; font-weight: bold;}
	table.responsive td:nth-of-type(6):before { content: "Notes"; font-weight: bold;}
	table.responsive td:nth-of-type(7):before { content: "Unit Price"; font-weight: bold;}
	table.responsive td:nth-of-type(8):before { content: "Total Price \A inc GST"; white-space: pre;  font-weight: bold;}
}

.errorBox {
    
    .alert-wrapper {
        max-width: 700px;

        .alert-message {
            color: black;
        }
    }
}

.lightBox {
    
    .alert-wrapper {
        max-width: 850px;
        width: 100%;
        margin: 0 10px;

        .alert-message.sc-ion-alert-md {
            color: black;
            max-height: unset;
        }
    }

    .fullWidth {
        margin-bottom: 1em;
    }
    
    textarea {
        width: 100%;
        height: 150px;
        color: #000000;
        background: #ffffff;
    
    }
}

.Accepted,
.Paid {
    background: url(./assets/imgs/acceptedStatus.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 21px;
}

.Ready {
    background: url(./assets/imgs/readyStatus.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 21px;
}

.Sent,
.Collected {
    background: url(./assets/imgs/sentStatus.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 21px;
}

.Received {
    background: url(./assets/imgs/receivedStatus.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 21px;
}


div.twoCols.filters {

    @media screen and (max-width: 900px) {
        .col:nth-child(2n-0),
        .col:nth-child(2n-1) {
            width: 100% !important;
        }
        .col:last-child {
            justify-content: unset !important;
            margin-left: 0 !important;
        }
    }
    
}

.history {
    width: 100%;
    .historyItem {
        .dateTime {

        }
        .message {
            
        }
    }
}

.questionList {
    display: flex;
    flex-direction: column;
    width: 100%;
    .question {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content:space-between;
        background: white;
        padding:10px;
        @media screen and (max-width: 800px) {
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        div {
            margin-right: 5px;
        }

        @media screen and (max-width: 800px) {
            margin-bottom: 20px;
        }

        .received {
            max-width: 150px;
            width: 100%;
            margin-right: 20px;
            @media screen and (max-width: 800px) { 
                
            }
        }

        .state {
            max-width: 22px;
            width: 100%;
            margin-right: 20px;
            @media screen and (max-width: 800px) { 
                
            }
        }

        .status {
            max-width: 80px;
            width: 100%;
            margin-right: 20px;
            text-align: center;
            @media screen and (max-width: 800px) { 
                
            }
        }

        .companyInfo {
            display: flex;
            flex-direction: column;            
            max-width: 280px;
            width: 100%;
            @media screen and (max-width: 800px) { 
                
            }
            .sellerCompany {
                font-weight: 700;
                width: 100%;
                margin-right: 20px;
                margin-bottom: 0;
                @media screen and (max-width: 800px) { 
                    width: 100%;
                    margin-right: 0;
                }
            }            
        }

        .sellerEnquiry {
            display: flex;
            flex-direction: column;
            max-width: 600px;
            width: 100%;
            .type {
                font-weight: 700;
                width: 100%;
                margin-right: 20px;
                margin-bottom: 0;
                @media screen and (max-width: 800px) { 
                    width: 100%;
                    margin-right: 0;
                }
            }
    
            .sellerQuestion {
                width: 100%;
                margin-right: 20px;
                margin-bottom: 0;
                @media screen and (max-width: 1300px) { 
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
        .answer {
            max-width: 600px;
            width: 100%;
            @media screen and (max-width: 1300px) { 
               width: 100%;
               margin-right: 0;
            }
        }

        .managementButtons {
            max-width: 99px;
            width: 100%;
            margin-right: 0;
            margin-bottom: 0;
        }
    }
}

ng-autocomplete {
    .autocomplete-container {
        box-shadow: none;
        .input-container {
            input {
                color: #006D6D;
                font-size: 16px !important;
                padding: 0 8px !important;
            }
        }

        .suggestions-container {
            ul {
                li {
                    a {
                        color: #006D6D;
                    }
                }
            }
        }
    }
}
.price {
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    input::placeholder {
        text-align: center;

        @media screen and (max-width: 1000px) {
            text-align: start;
        }
    }
}

.error {
    display: block;
    width: 100%;
    border: 4px solid #df3939 !important;
    background:  rgba(223, 57, 57, 0.2) !important;
    color: black !important;
    padding: 10px;
    margin-bottom: 10px;
}

.warning {
    display: block;
    width: 100%;
    border: 4px solid #dfca13 !important;
    background-color: rgba(223, 202, 19, 0.2) !important;
    color: black !important;
    padding: 10px;
    margin-bottom: 10px;
}

.informationMessage {
    display: block;
    width: 100%;
    border: 4px solid #006d6d !important;
    background-color: rgba(0, 109, 109, 0.2) !important;
    color: black !important;
    padding: 10px;
    margin-bottom: 10px;
}

.transactionInformation {
    display: block;
    font-size: 14px;
    font-weight: 400;
}
